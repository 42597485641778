.contact-info {
  padding-bottom: 25px;
  border-bottom: 1px solid $b_light;
  margin-bottom: 60px;
  @include max($s) {
    // padding-bottom: 50px;
    // margin-bottom: 50px;

    // border-bottom: 1px solid $b_light;
  }
  &__button {
    margin-top: 20px;
    margin-bottom: 40px;
    @include max($s) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &__form {
    @include max($s) {
      width: 100%;
      flex-direction: column;
    }
  }
  label {
    color: $n_grey;
    display: block;
    font-size: 15px;
    line-height: 19.6px;
    @include max($s) {
      font-weight: 500;
    }
  }

  input {
    font-size: 18px;
    line-height: 25.2px;
    &.error {
      border-color: #f21d28;
    }
  }
  .input-block {
    .error-span {
      display: none;
      height: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #f21d28;
    }

    &.error {
      padding-bottom: 5px;
      .error-span {
        display: block;
      }
    }
  }
}
