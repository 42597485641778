.drop {
  display: inline-block;
  min-width: 260px;
  z-index: 3;
  @include max($s) {
    min-width: 160px;
  }
  button {
    display: inline-flex;
    text-align: left;
    width: 100%;
  }
  &__wrp {
    position: relative;
    border: 1px solid $b_light;
    transition: all 0.2s;
    border-radius: 2px;

    &:hover {
      border: 1px solid $b_indigo;
    }

    &_one {
      .drop__btn {
        cursor: auto;
        .i-down {
          display: none;
        }

        &:hover {
          color: #000;
        }
      }

      &:hover {
        border-color: $b_light;
      }
    }
  }
  &__btn {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-end;
    color: #000;
    span {
      display: flex;
      align-items: center;
      line-height: 90%;
      margin-right: 3px;
    }
    @include max($s) {
      font-size: 18px;
    }
    padding: {
      top: 14.5px;
      left: 20px;
      right: 17px;
      bottom: 14.5px;
    }
    i {
      color: $b_sky;
      display: flex;
      transition: all 0.3s linear;
    }
    &:hover {
      color: $b_indigo;
    }
    &--inline {
      color: $n_grey;
    }
  }
  &__list {
    display: none;
    padding-bottom: 5px;
    transition: all 0.2s;
    border-radius: 2px;
    z-index: 3;
    &-item {
      padding: {
        top: 5px;
        bottom: 5px;
        left: 20px;
        right: 20px;
      }
      transition: all 0.2s;
      &:hover {
        color: $b_sky;
        .drop__size-add {
          color: $b_sky;
        }
      }
      &.active {
        color: $b_indigo;
        text-decoration: underline;
      }
      &.in-active {
        .drop__size {
          color: $n-middle;
          text-decoration: line-through;
          &-add {
            color: $n-middle;
            text-decoration: line-through;
          }
        }
      }
    }
    .drop__btn {
      border-bottom: 1px solid $b_light;
      margin-bottom: 15px;
    }

    @include max($s) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__pin {
    margin-left: auto;

    .i-house {
      font-size: 14px;
      margin-right: 5px;
    }

    &_main {
      margin-right: 10px;
    }

    @include max($s) {
      .i-house {
        font-size: 12px;
      }
    }
  }
  &__size {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 100%;
    width: 100%;
    &-add {
      font-weight: 400;
      color: $n_grey;
      margin-left: 10px;
    }
    &-link {
      margin-left: auto;
      margin-right: 10px;
      font-weight: 400;
      color: $n_grey;
      &--avail {
        text-decoration: none;
        color: $b_sky;
      }
    }
  }

  &.active {
    .drop__wrp {
      border: 1px solid transparent;
    }
    .drop__list {
      position: absolute;
      top: 0px;
      z-index: 1;
      display: block;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);
    }

    .drop__btn {
      .i-down {
        transform: rotate(180deg);
      }
    }
  }

  &--small {
    min-width: 170px;
    .drop {
      &__wrp {
        border: 0px !important;
      }
      &__list {
        top: -30px !important;
        left: 20px;
      }
    }
  }
}
