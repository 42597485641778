.cart {
  &__order {
    padding-left: 40px;
    border-left: 1px solid $b_light;
    margin-left: auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    @include max($s) {
      padding-left: 0;
      border: none;
    }
    @include max($m) {
      padding-left: 0;
      border: none;
      // padding-top: 50px;
      // border-top: 1px solid #e8f1fd;
      margin-top: 30px;
      padding-bottom: 50px;
    }

    &_border {
      @include max($m) {
        border-right: 1px solid #e8f1fd;
      }
      @include max($s) {
        border: none;
      }
    }
    &-title {
      font-size: 20px;
      line-height: 25.2px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    &-str {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &-delivery {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-total {
      font-weight: 600;
      font-size: 20px;
      border-top: 1px solid $b_light;
      padding-top: 20px;
      margin-top: 20px;

      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
      }
      &-value {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
      }
    }
    &-btn {
      display: block;
      width: 100%;
      text-align: center;
      position: relative;
      &-done {
        margin-top: 40px;
        margin-bottom: 10px;
      }
      &-share {
        margin-bottom: 40px;
      }
      &-apply {
        color: $n_grey;
        margin-bottom: 40px;
      }
      &-success {
        position: absolute;
        top: 115%;
        left: 0;
        background-color: white;
        font-size: 15px;
        line-height: 19.6px;
        padding: 20px;
        font-weight: 500;
        font-family: "Commons", sans-serif;
        color: $n_dark;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        &.visible {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &-promo {
      width: 100%;
      padding: 17.5px 20px;
      border: 1px solid $b_light;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    &-paytype {
      color: $n_grey;
      font-size: 15px;
      &_finish {
        margin-top: 60px;
      }

      @include max($m) {
        order: 2;
      }
    }
    &-list {
      padding-top: 60px;
      border-top: 1px solid $b_light;
      margin-top: 60px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-value {
          width: 180px;
          text-align: right;
        }
      }
      &-title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include max($s) {
        display: none;
      }
      @include max($m) {
        padding-top: 0px;
        border-top: none;
        margin-top: 0px;
      }
    }
    &-scores-value {
      display: flex;
      & > i::before {
        color: $b_indigo;
        font-size: 16px;
        line-height: 16px;
        margin-left: 6px;
      }
    }
  }

  .cart__order-promo-container {
    @include max($m) {
      display: flex;
      input {
        margin-bottom: 0px;
        margin-right: 10px;
        width: 350px;
      }
      .cart__order-btn {
        width: 160px;
        margin-bottom: 0px;
      }
    }
  }
}
@include max($m) {
  .make-order {
    .cart__order {
      .cart__order-cart-info {
        order: 1;
        margin-top: 50px;
      }
      .cart__order-btn {
        order: 0;
      }
    }
  }
}
