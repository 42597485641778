.profile-orders {
  &__row {
    display: flex;
    margin-top: -65px;
    margin-bottom: 70px;
    @include max($s) {
      // display: none;
      margin-top: -51px;
      margin-bottom: 20px;
    }
    & > .col-1 {
      visibility: hidden;
    }
  }
  &__col {
    padding: 20px;
    color: $n_darkest;
    box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);
    border-radius: 2px;
    background-color: white;
    max-height: 200px;
  }
}

.order-info-card {
  color: $n_darkest;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  &:not(:last-child) {
    // padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $b_light;
  }
  &:hover {
    background-color: $b_pale;
  }
  &__head {
    font-family: "Travels", sans-serif;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & i {
      font-size: 20px;
      color: $b_sky;
    }
  }
  &__title {
    margin-right: 15px;
  }
  &__status {
    margin-bottom: 30px;
  }
  &__btn {
    width: 100%;
  }
}

.all-orders {
  margin-bottom: 75px;
  @include max($s) {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  h4 {
    margin-bottom: 30px;
    @include max($s) {
      margin-bottom: 20px;
    }
  }

  &__btns {
    @include max($s) {
      flex-wrap: wrap;

      .btn:first-of-type {
        margin-right: 0px;
      }
    }
  }
}

.profile-orders__col {
  height: 100%;
  box-sizing: border-box;
}

.user-info {
  // margin-right: 20px;
  position: relative;
  overflow: hidden;

  @include max($s) {
    padding: 12px 14px 0px;
  }

  &__container {
    width: 230px;
    @include max($t) {
      width: 100%;
    }

    a {
      color: $b_indigo;
      @include max($s) {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include max($s) {
      flex-direction: row;
      justify-content: space-between;

      padding-bottom: 14px;
      margin-bottom: 12px;

      border-bottom: 1px solid #e8f1fd;
    }
  }

  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  &__percent {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: $n_grey;

    @include max($t) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__bonus,
  &__burn {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    display: flex;
    justify-content: space-between;

    margin-bottom: 10px;

    i {
      color: $b_indigo;
    }

    .danger {
      color: $r_red;
    }
  }

  .status-pic {
    width: 242px;
    height: 242px;

    position: absolute;

    top: 20px;
    right: -27px;

    @include max($t) {
      display: none;
    }
  }

  // &__number {
  //   font-family: "Travels", sans-serif;
  //   display: inline-block;
  //   margin-right: 15px;
  //   & i {
  //     font-size: 20px;
  //     color: $b_sky;
  //   }
  // }
  // &__info {
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 10px;
  //   & i {
  //     font-size: 20px;
  //     color: $b_sky;
  //   }
  // }

  // &__track {
  //   display: block;
  //   width: 100%;
  // }

  &.profile-orders__col_adv {
    padding: 12px 14px 10px;
    .user-info__container {
      width: 350px;

      .user-info__bonus {
        justify-content: flex-start;

        p:first-of-type {
          margin-right: 10px;
        }
      }

      .profile-bonuses__info-temporary {
        margin-top: 10px;

        .value {
          line-height: 17px;
        }

        .date {
          font-size: 15px;
        }
      }
    }
    .status-pic {
      width: 171px;
      height: 171px;
      right: -85px;
      top: 13px;
    }
  }
}

.bonuses-action {
  display: flex;
  flex-direction: column;
  // @include max($s) {
  //   display: none;
  // }

  &__title {
    font-weight: 600;
    font-family: "Travels", sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: $b_indigo;

    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $b_light;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    & > div {
      display: flex;
    }
  }
  &__order-number {
    &.plus {
      color: $g_green;
    }
    &.minus {
      color: $r_red;
    }
  }
  &__order-cost {
    margin-left: 10px;
    &.plus {
      color: $g_green;
    }
    &.minus {
      color: $r_red;
    }
    &.future {
      color: $b_indigo;
    }
  }

  &__date {
    font-size: 15px;
    line-height: 20px;
    color: $n_grey;
  }
}
.order-status {
  font-size: 15px;
  line-height: 13px;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: 26px;
  display: inline-block;
  color: $b_indigo;
  background-color: $b_light;
  &.danger {
    background-color: $r_blash;
    color: $r_dark;
  }
  &.access {
    color: #24b34c;
    background-color: #f1fff5;
  }
  &.complite {
    color: #717188;
    background-color: #fafafd;
  }
}
