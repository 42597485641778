@font-face {
  font-family: "icomoon";
  src: local("icomoon"), url("fonts/icomoon.eot?dnexpx");
  src: url("fonts/icomoon.eot?dnexpx#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?dnexpx") format("truetype"),
    url("fonts/icomoon.woff?dnexpx") format("woff"),
    url("fonts/icomoon.svg?dnexpx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gift:before {
  content: "\e900";
}
