.cart {
  margin-top: 50px;
  margin-bottom: 100px;
  @include max($s) {
    margin-bottom: 150px;
  }
  color: $n_darkest;

  &__title {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    @include max($s) {
      margin-bottom: 30px;
    }
  }
  &__cards {
    margin-bottom: 150px;
    @include max($s) {
      margin-bottom: 0px;
    }
    &-container {
      @include max($s) {
        margin-bottom: 50px;
      }
    }

    @include max($s) {
      .sert-block__text {
        margin-bottom: 20px;
        .input-block {
          padding-bottom: 0px;

          label {
            display: none;
          }
        }
      }
      .sert-block__photo {
        margin-bottom: 0px;
      }
    }
  }
}
