.mobile-filters {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: scroll;
  top: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 100vh;
  background-color: white;
  color: $n_darkest;
  &__top {
    // display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  &__icon {
    &-close {
      margin-left: auto;
      display: block;
      font-size: 20px;
    }
    &-back {
      transform: rotate(90deg);
      font-size: 20px;
      color: black;
    }
  }
  &__title {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
  }
  &__item {
    font-size: 15px;
    line-height: 19.6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.special {
      display: block;
    }
    &-price {
      margin-top: 20px;
      &-count {
        display: flex;
        text-align: center;
        & > div {
          border: 1px solid $n_middle;
          box-sizing: border-box;
          text-align: center;
          padding: 4px 10px;
          width: 142px;
          height: 30px;
        }
      }
      &-dash {
        width: 11px;
        height: 1px;
        background-color: $n_middle;
        align-self: center;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $b_light;
    }
    &-title {
      font-weight: 600;
      width: 100%;
    }
    &-choosen {
      font-weight: 500;
      color: $n_grey;
      padding-right: 10px;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > i {
        transform: rotate(-90deg);
        font-size: 20px;
        color: $n_grey;
      }
    }
  }
  &__btns {
    margin-top: 40px;
  }
  &__subitem {
    font-size: 15px;
    line-height: 19.6px;
    font-weight: 500;
    color: $n_dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $b_light;
    }
    &-title {
      width: 100%;
    }
  }
  &__search {
    transform: translateY(-20px);
    border: none;
    border-bottom: 1px solid $n_middle;
    width: 100%;
    &::placeholder {
      color: $n_middle;
    }
  }
  &__size {
    &-title {
      color: $n_grey;
      margin-bottom: 20px;
    }
    &-vars {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  &__year {
    display: flex;
    position: relative;
    &-input {
      width: 50%;
      border: 1px solid $n_middle;
      padding: 7.5px 20px;
      text-align: center;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    & > .dash {
      width: 11px;
      height: 1px;
      background-color: $n_middle;
      position: absolute;
      left: 48%;
      top: 45%;
    }
  }
}
