.catalog-sale {
  color: $n_darkest;
  &__head {
    background-color: $b_pale;
    // padding-bottom: 90px;
    border-bottom: 1px solid $b_light;

    @include max($s) {
      background-color: transparent;
      border: none;
      margin-bottom: 0;
    }
    .slick-next {
      top: 110% !important;
      right: 15px !important;
      &::before {
        font-family: "icons" !important;
        content: "\e900" !important;
      }
    }

    .slick-prev {
      top: 110% !important;
      left: calc(100% - 70px) !important;
      &::before {
        font-family: "icons" !important;
        content: "\e900" !important;
      }
    }
  }
  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.6px;
    margin-bottom: 50px;
  }
  &__body {
    &-categlist {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $b_light;
      @include max($s) {
        border: none;
      }
      &-item {
        margin-right: 20px;
        margin-bottom: 20px;
        @include max($s) {
          margin-right: 15px;
          margin-bottom: 15px;
          & > a {
            padding: 8px;
          }
        }
      }
    }
  }
  &__select {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 260px;
    margin-left: auto;
  }

  &__prods {
    display: flex;
    flex-wrap: wrap;
  }
  &__btn {
    width: 100%;
    margin-top: 160px;
    margin-bottom: 50px;
    @include max($s) {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
  &__pagination {
    margin-bottom: 90px;
  }
}
.sale-card-link {
  &:hover {
    text-decoration: none;
    .sale-card__title {
      color: $b_indigo;
      text-decoration: underline;
    }
  }
}
.sale-card {
  padding: 10px;
  cursor: pointer;

  &__img {
    width: 100%;
    margin-bottom: 20px;
  }
  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 25.2px;
    margin-bottom: 5px;
  }
  &__descr {
    color: $n_grey;
  }
}
