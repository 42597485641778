.cart-modal {
  background-color: white;
  width: 600px;
  z-index: 100;
  color: $n_darkest;
  position: absolute;
  top: 45px;
  right: 0;
  @include min("1352px") {
    right: -45px;
  }
  border-bottom: 1px solid $b_light;

  box-sizing: border-box;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $b_light;
    padding-bottom: 20px;
  }
  &__content {
    padding-top: 40px;
    .col {
      padding: 0;

      &-9 {
        padding-left: 10px !important;
      }
    }
    .simplebar-track {
      right: -13px;
    }
  }
  &__amount {
    color: $n_grey;
    margin-left: 20px;
  }
  &__price {
    color: $r_red;
  }
  &__footer {
    padding-top: 30px;
    .btn {
      width: 50%;
    }
  }
  .cart-card {
    &__desc {
      display: flex;
      flex-direction: column;
    }
    padding-bottom: 20px !important;
    &__title {
      margin-bottom: 0px;
      a {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }
    &__info {
      p,
      .p {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 0px;
      }
    }
    &__foot {
      margin-top: auto;
    }
  }
  .input--amount {
    margin-right: 0;
  }
  .row {
    &.clean {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
