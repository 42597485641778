.service-bonuse {
  &__slider {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  &__slide {
    margin-right: 50px;
    &-head {
      display: flex;
      align-items: center;
      margin-bottom: 38px;
    }
    &-line {
      height: 1px;
      width: 100%;
      margin-right: 10px;
      margin-left: 10px;
      background-color: $b_indigo;
    }
    &-title {
      // font-weight: 600;
      // color: $n_dark;
      margin-bottom: 10px;

      @include max($s) {
        font-weight: 600;
      }
    }
    &-condition {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e8f1fd;
    }
    &-scores {
      margin-bottom: 10px;
      &-value {
        margin-left: 5px;
        font-weight: 600;
      }
    }
    &-delivery {
      margin-left: 5px;
      font-weight: 600;
    }
  }
  .slick-next {
    top: 110% !important;
    right: 10px !important;
    z-index: 1000;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
    @include max($s) {
    }
  }

  .slick-prev {
    top: 110% !important;
    right: 50px !important;
    left: auto;
    z-index: 1000;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }

    @include max($s) {
      left: 84% !important;
    }
  }

  .service-page__item-info_gift {
    img {
      width: 40%;
      margin-bottom: 25px;

      @include max($s) {
        width: 100%;
      }
    }
  }

  .service-page__item-info_use-bonus {
    img {
      width: 70%;
      margin-bottom: 25px;

      @include max($s) {
        width: 100%;
      }
    }
  }
}
