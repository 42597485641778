.breadcrumb {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 15px;
  line-height: 19.6px;
  @include max($s) {
    padding-top: 30px;
  }

  &.hide {
    @include max($s) {
      padding: 40px 0px 0px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    &:after {
      content: " / ";
    }
    &:not(:last-child) {
      margin-right: 5px;
      color: $b_indigo;
    }
    &:nth-last-child(1),
    &:nth-last-child(2) {
      // color: $n_grey;
      &:after {
        content: none;
      }
    }
  }

  &__link {
    &.active {
      display: none;
      cursor: auto;
      pointer-events: none;
      color: #717188;
    }
  }
}
