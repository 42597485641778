.delivery-var {
  padding-bottom: 50px;
  border-bottom: 1px solid $b_light;
  margin-bottom: 60px;
  @include max($s) {
    margin-bottom: 50px;
  }
  h2 {
    margin-bottom: 40px;
    @include max($s) {
      margin-bottom: 30px;
    }
  }
  label {
    color: $n_grey;
    display: block;
    font-size: 15px;
    line-height: 19.6px;
  }
  &__title {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 20px;
  }
  &__select {
    box-sizing: border-box;
    margin-bottom: 40px;
    @include max($s) {
      margin-bottom: 30px;
    }
  }
  &__type {
    margin-bottom: 40px;
    & > .btn:not(:last-child) {
      margin-bottom: 10px;
      @include max($s) {
        margin-right: 10px;
      }
    }
  }
  &__descr {
    &-self {
      display: flex;
      font-weight: 500;
      @include max($s) {
        flex-direction: column;
      }
      & > img {
        @include max($s) {
          margin-bottom: 20px;
        }
      }
      &-text {
        margin-left: 30px;
        @include max($s) {
          margin-left: 0;
        }
      }
      &-title {
        margin-bottom: 20px;
      }
      &-info {
        font-size: 15px;
        line-height: 19.6px;
      }
      &-address {
        margin-bottom: 10px;
      }
    }
    &-courier {
      &-form {
        input {
          box-sizing: border-box;
          padding: 17.5px 20px 11.5px 20px;
          border-radius: 2px;
          border: 1px solid $b_light;
          width: 100%;
          margin-bottom: 10px;
          &::placeholder {
            color: $n_darkest;
          }
        }
      }
      &-comment {
        margin-top: 25px;
        @include max($s) {
          margin-top: 35px;
        }
      }
    }
  }
}

.delivery-var__select {
  position: relative;

  &[aria-expanded="true"] {
    .react-dadata__input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none;
    }
  }

  & > div {
    width: 100%;
    height: 50px;
    .react-dadata__input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 4px;
      padding: 18px 20px 18px;
    }
  }

  .react-dadata__suggestions {
    position: absolute;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: #fff;
    padding: 10px 20px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    & .city-choose__elem {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      span {
        text-align: left;
      }
      &:hover {
        span {
          color: #70a4ef;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
