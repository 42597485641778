.prodcard-list {
  margin-top: 100px;
  @include max($s) {
    overflow: hidden;
    margin-top: 50px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max($s) {
      margin-bottom: 25px;
    }
  }
  &__title {
    color: $n_darkest;
    @include max($s) {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__button {
    color: $b_sky;
    display: flex;
    align-items: center;
    font-family: "Travels", sans-serif;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 600;
    @include max($s) {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 600;
    }
    & > i {
      font-size: 24px;
      color: $b_sky;
      margin-left: 10px;
      @include max($s) {
        font-size: 17px;
      }
    }
  }

  .prod-col {
    @include min($d) {
      &:nth-child(n + 5) {
        border-bottom: none;
      }
    }

    @include max($s) {
      &:nth-child(n + 3) {
        border-bottom: none;
      }
    }
    @include max($t) {
      &:nth-child(n + 7) {
        border-bottom: none;
      }
    }
  }
}
