.mailing {
  margin-top: 60px;
  padding: 100px;
  background-image: url(../../img/mailing-bg.png);
  background-size: cover;
  color: white;
  background-color: rgba($color: #02081e80, $alpha: 0.5);

  @include max($m) {
    padding: 50px 20px;
  }
  @include max($s) {
    margin-top: 50px;
    // padding: 0px;
    padding: 50px 0px 0px;
  }
  &__row {
    @include max($s) {
      flex-direction: column;
    }
  }
  &__col {
    @include max($s) {
      // margin-top: 20px;
      max-width: 100%;
    }
  }
  &__up {
    padding-bottom: 50px;
    border-bottom: 1px solid $b_light;
    @include max($s) {
      border-bottom: none;
      padding-bottom: 20px;
    }
  }
  &__down {
    margin-top: 50px;
    @include max($s) {
      margin-top: 0;
      padding-bottom: 50px;
    }
    &-arrow {
      transition: all 0.3s linear;
      color: #fff;
      @include max($s) {
        font-size: 26px;
      }
      &.up {
        transform: rotate(180deg);
      }
    }
    &-info {
      @include max($s) {
        transition: all 0.3s linear;
        height: 0;
        opacity: 0;
        &.open {
          height: 100%;
          opacity: 1;
        }
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      font-family: "Travels", sans-serif;
      font-weight: 600;
    }
  }
  &__input-group {
    display: flex;
    margin-bottom: 22px;
    @include max($s) {
      flex-direction: column;
    }
    @include max($t) {
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  &__input {
    border: none;
    border-radius: 2px;
    padding: {
      top: 13px;
      bottom: 11px;
      left: 20px;
      right: 20px;
    }
    width: calc(50% - 30px);
    margin-right: 20px;
    box-sizing: border-box;
    @include max($s) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &__button {
    width: calc(50% - 30px);
    @include max($s) {
      width: 100%;
    }
    &.success {
      background-color: $g_green;
    }
    &.danger {
      background-color: $r_red;
    }
  }
}

.mailing__checkbox-label {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  padding-top: 5px;
  @include max($s) {
    align-items: flex-start;
    font-size: 16px;
    line-height: 19.6px;
  }
  & > i {
    margin-right: 10px;
  }
  & > i::before {
    transition: all 0.3s linear;
  }
  input {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    @include max($s) {
      margin-top: 3px;
    }
  }
}
.mailing__checkbox {
  display: none;
}

// .mailing__checkbox {
//   display: none;
// }

// .mailing__checkbox + span {
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   background-color: white;
//   top: 0;
//   left: 0;
// }

// .mailing__checkbox + span:before {
//   position: absolute;
//   top: 1px;
//   left: 3px;
//   right: 0;
//   bottom: 0;
//   opacity: 0;
//   transition: all 0.3s ease-in-out;
//   content: "\2713";
// }

// .mailing__checkbox:checked + span,
// .mailing__checkbox:checked + span:before {
//   opacity: 1;
//   background-color: $b_indigo;
// }
