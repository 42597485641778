@mixin max($media) {
  @media screen and (max-width: $media) {
    @content;
  }
}

@mixin min($media) {
  @media screen and (min-width: $media) {
    @content;
  }
}
