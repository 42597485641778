.prodchecked-swiper {
  margin-bottom: 100px;
  @include max($s) {
    margin-bottom: 70px;
  }
  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 20px;
    color: $n_darkest;
    @include max($s) {
      font-size: 18px;
      line-height: 25.2px;
    }
  }

  .slick-list {
    margin: 0 -10px;
  }

  .slick-slide > div {
    padding: 0 10px;
  }

  .slick-next {
    top: -10% !important;
    right: 15px !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-prev {
    top: -10% !important;
    left: calc(100% - 70px) !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-dots {
    @include max($s) {
      bottom: -40px !important;
    }
  }
  .slick-arrow {
    @include max($s) {
      display: none !important;
    }
  }
}

.slick-dots .slick-active > div {
  width: 16px !important;
  background-color: $b_light !important;
  transition: all 0.3s linear;
}
