@import "./vars/colors"; //стили настроек
@import "./vars/icons";
@import "./vars/mix";
@import "./vars/size";
@import "text";
@import "elements";
@import "input";
// @import "fonts";
@import "settings";
@import "grid";
@import "table";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../components/Footer/footer.scss"; //стили компонентов
@import "../components/Header/header.scss";
@import "../components/LoadingOverlay/loading-overlay.scss";
@import "../components/Mailing/mailing.scss";
@import "../components/Navigation/navigation.scss";
@import "../components/ProdCardList/prod-card-list.scss";
@import "../components/ProdCheckedSwiper/prod-checked-swiper.scss";
@import "../components/ProdCard/prod-card.scss";
@import "../components/ProdList/prod-list.scss";
@import "../components/Filters/filters.scss";
@import "../components/Breadcrumb/bread-crumb.scss";
@import "../components/Drop/drop.scss";
@import "../components/CartOrderInfo/cart-order-info.scss";
@import "../components/ProfileHeader/profile-header.scss";
@import "../components/SizeModal/size-modal.scss";
@import "../components/CartModal/cart-modal.scss";
@import "../components/MobileMenu/mobile-menu.scss";
@import "../components/CartCard/cart-card.scss";
@import "../components/AuthorizationModal/authorization-modal.scss";
@import "../components/MobileFilters/mobile-filters.scss";
@import "../components/Modal/modal.scss";
@import "../components/RedLine/red-line.scss";

@import "../pages/main/main.scss"; //стили страниц
@import "../pages/catalog/catalog.scss";
@import "../pages/product/product.scss";
@import "../pages/cart/cart.scss";
@import "../pages/make-order/make-order.scss";
@import "../pages/personal-account/personal-account.scss";
@import "../pages/service-page/service-page.scss";
@import "../pages/search-result/search-result.scss";
@import "../pages/search-result/search-no-result/search-no-result.scss";
@import "../pages/catalog/catalog-sale/catalog-sale.scss";
@import "../pages/catalog/catalog-sale-open/catalog-sale-open.scss";
