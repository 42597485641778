.search-no-result {
  color: $n_darkest;
  &__head {
    padding-top: 70px;
    padding-bottom: 80px;
    background-color: $b_pale;
    border-bottom: 1px solid $b_light;
  }

  &__title {
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 10px;
  }
  &__info {
    color: $n_dark;
    margin-bottom: 30px;
  }
  &__contact {
    display: flex;
    color: $n_dark;
    &-phone,
    &-email {
      margin-right: 20px;
    }
  }
  &__contact-info {
    display: flex;
    @include max($s) {
      flex-direction: column;
    }
  }
  &__body {
    padding-top: 40px;
    padding-bottom: 150px;
    &-categlist {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &-item {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
    &-cardlist {
      margin-top: 20px;
    }
  }
}
