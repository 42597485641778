.search-result {
  color: $n_darkest;
  padding-top: 50px;
  padding-bottom: 100px;
  &__title {
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 50px;
  }
  &__search {
    position: relative;
    margin-bottom: 40px;
  }
  &__input {
    padding: 12.5px 20px 6.5px 20px;
    width: 100%;
    border: 1px solid $b_sky;
    border-radius: 2px;
    box-sizing: border-box;
  }
  &__btn {
    position: absolute;
    top: 30%;
    right: 3%;
  }
  &__select {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 260px;
    margin-left: auto;
  }
  &__pagination {
    margin-top: 20px;
  }
}
