@font-face {
  font-family: "icons";
  /* src: url("./fonts/icons.eot"); */
  src: local("icomoon"), url("fonts/icons.woff") format("woff");
  src: url("./fonts/icons.eot") format("embedded-opentype"),
    url("./fonts/icons.ttf") format("truetype"),
    url("./fonts/icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Commons";
  src: url("./fonts/TTCommons-DemiBold.woff2") format("woff2"),
    url("./fonts/TTCommons-DemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Commons";
  src: url("./fonts/TTCommons-Medium.woff2") format("woff2"),
    url("./fonts/TTCommons-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Commons";
  src: url("./fonts/TTCommons-Regular.woff2") format("woff2"),
    url("./fonts/TTCommons-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Travels";
  src: url("./fonts/TTTravels-DemiBold.woff2") format("woff2"),
    url("./fonts/TTTravels-DemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Travels";
  src: url("./fonts/TTTravels-Medium.woff2") format("woff2"),
    url("./fonts/TTTravels-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Travels";
  src: url("./fonts/TTTravels-Regular.woff2") format("woff2"),
    url("./fonts/TTTravels-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Cyr';
  src: url('./fonts/Druk-Text-Wide-Cyr-Medium.otf') format('opentype'),
  url('./fonts/Druk-Text-Wide-Cyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
