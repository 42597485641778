.cart-card {
  &:not(:first-child) {
    padding-top: 30px;
    @include max($s) {
      padding-top: 20px;
    }
  }
  position: relative;
  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 1px solid $b_light;
    @include max($s) {
      padding-bottom: 20px;
    }
  }
  &__img {
    width: 100%;
  }
  &__close {
    & > i {
      font-size: 16px;
    }
  }
  &__desc {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    a {
      font-size: inherit;
    }
    .p {
      width: 100%;
    }
    @include max($s) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }
  &__info {
    color: $n_grey;
    &-size {
      & > i {
        font-size: 16px;
        color: $b_sky;
        margin-left: 7px;
      }
    }
    .p,
    p {
      line-height: 21px;
      margin-bottom: 10px;
      @include max($s) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
      }
    }
    .count-in-cart {
      display: none;
      @include max($s) {
        display: block;
      }
    }
  }
  &__amount {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    border: 1px solid $b_light;
    margin-right: 20px;
    &-input {
      width: 50px;
      border: none;
      &::placeholder {
        text-align: center;
        color: $n_darkest;
        font-size: 18px;
        line-height: 20.7px;
      }
    }
    & i {
      font-size: 16px;
      color: $b_sky;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    &-old {
      text-decoration: line-through;
      margin-right: 10px;
      font-size: inherit;
      line-height: inherit;
    }
    &-new {
      color: $r_red;
      font-size: inherit;
      line-height: inherit;
    }

    @include max($s) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__foot {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max($s) {
      margin-top: 0;
      margin-top: auto;
    }
  }
  &__change {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $b_sky;
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    & > i {
      font-size: 16px;
      line-height: 16px;
      margin-left: 5px;
    }
  }

  &__change-mobile {
    display: none;
    @include max($s) {
      width: 100%;
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
      .drop {
        flex-shrink: 1;
        width: 100%;
        margin-right: 10px;
      }
      .input--amount {
        margin-bottom: 0px;
      }
    }
  }

  &__choose {
    margin-top: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    .sert-block-choose-sum {
      .btn {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  &__price-gift {
    i {
      margin-left: 5px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .sert-block__photo {
    order: 0;
  }
}

.i-down {
  display: inline-block;
  transition: transform 0.2s linear;

  &.active {
    transform: rotate(180deg);
  }
}

.close-block {
  max-height: 0px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  &.open {
    opacity: 1;
    max-height: 500px;
    visibility: visible;
  }

  &#giftChangeSum {
    // padding-bottom: 20px;
  }
}
