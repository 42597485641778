.service-shop {
  line-height: 25.2px;
  @include max($s) {
    font-size: 15px;
    line-height: 19.6px;
    font-weight: 500;
  }
  .col {
    margin-bottom: 40px;
    &:not(:last-child) {
      @include max($s) {
        padding-bottom: 40px;
        border-bottom: 1px solid $b_light;
      }
    }
  }
  &__map {
    width: 100%;
    height: 340px;
    background-image: url(../../../img/store-map-v2.jpg);
    background-position: center;
    background-size: cover;

    @include max($s) {
      background-image: url(../../../img/store-map-v2_m.jpg);
    }

    @include max($t) {
      background-image: url(../../../img/store-map-v2_t.jpg);
    }

    &-link {
      margin-top: 10px;
      margin-bottom: 40px;
      color: $b_sky;
    }
  }
  &__contacts {
    @include max($s) {
      display: flex;
    }

    a {
      display: block;
    }
  }
  &__connect {
    margin-top: 5px;
    @include max($s) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
  &__subtitle {
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 20px;
    @include max($s) {
      font-size: 18px;
    }
  }
  &__address {
    margin-bottom: 20px;
  }
}
