.make-order {
  margin-top: 50px;
  color: $n_darkest;
  font-size: 18px;
  line-height: 25.2px;
  &__info {
    @include max($s) {
      margin-bottom: 60px;
    }

    & > div {
      & > div {
        border-bottom: none;
        margin-bottom: 0px;

        @include max($s) {
          margin-bottom: 50px;
          padding-bottom: 50px;

          border-bottom: 1px solid $b_light;
        }
      }
    }
  }
  &__subtitle {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 20px;
    @include max($s) {
      margin-bottom: 10px;
    }
  }
  &__number {
    span {
      font-family: "Travels", sans-serif;
      font-weight: 600;
    }
  }
  &__btns {
    display: flex;
    margin-top: 20px;
    margin-bottom: 100px; //ето просто тест

    @include max($s) {
      flex-direction: column;
      margin-bottom: 50px;
    }
    & > .btn:not(:last-child) {
      margin-right: 20px;
      @include max($s) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    @include max($s) {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 30.6px;
      font-weight: 500;
    }
  }
}

@import "./ContactInfo/contact-info.scss";
@import "./DeliveryVar/delivery-var.scss";
@import "./BonusPay/bonus-pay.scss";
