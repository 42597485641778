// .flex-table {
//   display: flex;
//   flex-direction: column;
//   border: 1px solid #ccc;
//   background: #fff;
// }
// .flex-table thead {
//   color: #fff;
//   background: #009485;
// }
// .flex-table thead tr,
// .flex-table tbody tr {
//   display: flex;
// }
// .flex-table tbody tr + tr {
//   border-top: 1px solid #ccc;
// }
// .flex-table thead tr th,
// .flex-table tbody tr td {
//   display: flex;
//   flex: 1;
//   padding: 0.5em;
// }
// @media screen and (max-width: 640px) {
//   .flex-table {
//     border: 0;
//   }
//   .flex-table thead {
//     display: none;
//   }
//   .flex-table tbody tr {
//     flex-direction: column;
//     margin: 1em;
//     border: 1px solid #ccc;
//   }
//   .flex-table tbody tr td {
//     flex-direction: column;
//   }
//   .flex-table tbody tr td + td {
//     border-top: 1px solid #ccc;
//   }
//   .flex-table tbody tr td:before {
//     display: flex;
//     align-items: center;
//     margin: -0.5em -0.5em 0.75em -0.5em;
//     padding: 0.5em;
//     content: attr(data-label);
//     color: #fff;
//     background: #009485;
//   }
// }

table {
  font-size: 16px;
  line-height: 19.6px;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table {
  &__str-hide {
    display: none;
  }
}
thead {
  color: white;
  border-radius: 2px;
  th {
    padding: 10px;
    background-color: $n_darkest;
    text-align: left;
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  td:not(:first-child) {
    width: 86px;
  }
}
tbody {
  td {
    padding: 10px;
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  tr {
    &:nth-child(even) {
      background-color: $b_light;
    }
  }
  &.odd {
    tr {
      &:nth-child(even) {
        background-color: transparent;
      }
      &:nth-child(odd) {
        background-color: $b_light;
      }
    }
  }
}
