.settings-contact-info {
  background-color: $b_light;
  padding-top: 50px;
  padding-bottom: 60px;
  @include max($s) {
    background-color: transparent;
    padding-bottom: 47px;
  }
  &__title {
    font-weight: 600;
    font-family: "Travels", sans-serif;
    color: $n_darkest;
    margin-bottom: 30px;

    @include max($s) {
      @include h4-m;
    }
  }
  &__label {
    display: block;
    font-weight: 500;
    color: $n_grey;
    font-size: 15px;
    line-height: 19.6px;
  }
  input {
    width: 100%;
    border: 1px solid $b_light;
    padding: 17.5px 20px 11.5px 20px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25.2px;
  }
  &__form {
    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__btn {
    margin-top: 20px;
    @include max($s) {
      width: 100%;
      margin-left: 0px;
    }
  }

  form {
    &.success {
      .settings-contact-info__btn {
        background-color: #24b34c;
      }
    }
    &.error {
      .settings-contact-info__btn {
        background-color: #f21d28;
      }
    }
  }
}
