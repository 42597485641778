.catalog-sale-open {
  color: $n_darkest;
  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.6px;
    margin-bottom: 50px;
  }
  &__body {
    &-categlist {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &-item {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
  &__select {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 260px;
    margin-left: auto;
  }
  &__btn {
    width: 100%;
    margin-top: 160px;
    margin-bottom: 50px;
  }
  &__pagination {
    margin-bottom: 90px;
  }
}
