.mobilecateg-list {
  background-color: $n_darkest;
  padding-top: 50px;
  padding-bottom: 50px;
  &__lists {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
    padding-left: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__link {
    box-sizing: border-box;
    padding: 10px 16px 6px;
    margin-right: 10px;
    // border: 1px solid $b_indigo;
    border-radius: 2px;
    color: #fff;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    background-color: #222736;

    &.super-a {
      background-color: #096ade;
    }
  }
  &__sublist {
    margin-top: 20px;
    line-height: 20.7px;
    &-item:not(:last-child) {
      margin-bottom: 20px;
    }
    &-link {
      // font-size: 18px;
      line-height: 20.7px;
      color: #fff;

      @include max($s) {
        line-height: inherit;
      }
    }
  }
  .slick-track {
    // padding-top: 10px;
    // padding-bottom: 10px;
    // margin-left: -10px;
  }
  .slick-slide {
    // padding-left: 10px;
    // padding-right: 10px;
  }
  // .slick-arrow {
  //   display: none !important;
  // }
}
