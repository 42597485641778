.input {
  &--amount {
    padding: {
      right: 20px;
      left: 20px;
      top: 11px;
      bottom: 12px;
    }
    display: flex;
    align-items: center;
    border: 1px solid $b_light;
    border-radius: 2px;
    margin-right: 20px;
    @include max($s) {
      margin-bottom: 20px;
      margin-right: 0;
    }
    button {
      color: $b_sky;
      transition: all 0.2s;
      &:hover {
        color: $b_indigo;
      }
      &:active {
        color: $b_violet;
      }
    }
    &__input {
      width: 50px;
      border: none;
      text-align: center;

      &::placeholder {
        text-align: center;
        color: $n_darkest;
        font-size: 18px;
        line-height: 20.7px;
      }

      @include max($s) {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
  width: 100%;
  border-radius: 2px;
  border: 1px solid $b_light;
  padding: 11px;
  box-sizing: border-box;

  @include max($s) {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  &.success {
    border-color: $g_green;
  }

  &::placeholder {
    font-size: 18px;
    font-weight: 400;
    font-family: "Commons", sans-serif;
  }
}

textarea {
  height: 150px;
}

label {
  &.label-wrp {
    display: inline-block;
    font-size: 15px;
    line-height: 19.6px;
    font-weight: 500;
    color: $n_grey;
    width: 100%;
    position: relative;
  }
  &.checkbox,
  &.radio {
    color: $n_dark;
    font-weight: 400;
    font-size: 18px;
    input {
      margin-right: 10px;
    }
  }
  &.success {
    color: $g_green;
  }
}

input[type="radio"] {
  border: 1px solid $n_dark;
  border-radius: 100%;
  background-color: transparent;
  color: white;
  height: 14px;
  width: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    height: 7px;
    width: 7px;
    background-color: $b_indigo;
    border-radius: 100%;
    opacity: 0;
    transition: all 0.2s;
  }
  &:checked {
    border: 1px solid $b_indigo;
    &::after {
      opacity: 1;
      transition: all 0.2s;
    }
  }
}

input[type="checkbox"] {
  border: 1px solid $n_dark;
  background-color: transparent;
  color: white;
  height: 13px;
  width: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;

  &.white {
    background-color: #fff;
  }

  &::after {
    font-family: "icons";
    content: "\e909";
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    font-size: 16px;
    color: white;
    opacity: 0;
    transition: all 0.2s;
  }
  &:checked {
    border: 1px solid $b_indigo;
    background-color: $b_indigo;
    &::after {
      opacity: 1;
      transition: all 0.2s;
    }
  }
}

.input-block {
  position: relative;
  padding-bottom: 25px;

  .input-error {
    visibility: hidden;
    opacity: 0;

    transition: opacity 0.3s linear;
  }

  &.view-error-mess {
    .input-error {
      visibility: visible;
      opacity: 1;
    }
  }

  label {
    color: $n_grey;
    display: block;
    font-size: 15px;
    line-height: 19.6px;
    @include max($s) {
      font-weight: 500;
    }
  }

  input {
    font-size: 18px;
    line-height: 25.2px;
    &.error {
      border-color: #f21d28;
    }
  }
}

.input-error {
  position: absolute;
  bottom: 0px;
  left: 1px;

  font-size: 15px;
  line-height: 20px;

  color: #f21d28;
}
