.settings-password {
  padding-top: 60px;
  padding-bottom: 120px;
  @include max($s) {
    padding-top: 50px;
    padding-bottom: 44px;
  }
  &__title {
    font-weight: 600;
    font-family: "Travels", sans-serif;
    color: $n_darkest;
    margin-bottom: 30px;

    @include max($s) {
      @include h4-m;
    }
  }
  &__label {
    display: block;
    font-weight: 500;
    color: $n_grey;
    font-size: 15px;
    line-height: 19.6px;
  }
  &__input {
    width: 100%;
    border: 1px solid $b_light;
    padding: 17.5px 20px 11.5px 20px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25.2px;
    @include max($s) {
      margin-bottom: 35px;
    }
  }
  &__btn {
    margin-top: 20px;
    @include max($s) {
      width: 100%;
      margin-top: 0;
      margin-left: 0px;
    }
  }
}
