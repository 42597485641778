.brand-swiper {
  margin-top: 100px;
  margin-bottom: 100px;
  @include max($s) {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 20px;
  }
  &__slide {
    // cursor: pointer;
    margin-right: 50px;
    text-align: center;
    & > img {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;

      filter: grayscale(100%);
      transition: 0.4s;
      &:hover {
        filter: grayscale(0);
      }
    }
  }
  .slick-next {
    top: 30% !important;
    right: 15px !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-prev {
    top: 30% !important;
    left: calc(100% - 70px) !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }
  .slick-list {
    margin-right: 80px !important;
    @include max($s) {
      margin-right: 0px !important;
    }
  }

  .slick-arrow {
    @include max($s) {
      display: none !important;
    }
  }
  .slick-slide {
    position: relative;
    padding: 0px 5px;
    box-sizing: border-box;
    margin: 0px 15px;
  }
  .slick-track {
    display: flex !important;
    align-items: center;
  }
}
