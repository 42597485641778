.footer {
  padding: 50px 100px;
  background-color: $n_black;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.6px;
  color: white;
  .h4 {
    margin-bottom: 20px;
  }

  @include max("1279px") {
    padding: 50px 20px;
  }

  @include max($s) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 50px 0px;
  }
  &__row {
    @include max($s) {
      flex-direction: column;
    }
  }
  &__col {
    @include max($s) {
      max-width: 100%;
    }
  }
  &__phone {
    margin-top: 20px;
    margin-bottom: 15px;
    @include max($s) {
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    @include max($m) {
      display: flex;
      flex-wrap: wrap;
    }
    &-number {
      font-family: "Travels", sans-serif;
      font-weight: 600;
      line-height: 25px;
      margin-right: 20px;
      @include max($s) {
        width: 50%;
        margin: 0;
      }
    }
    &-label {
      font-size: 14px;
      font-weight: 500;
      padding-top: 6px;
      padding-bottom: 6px;
      @include max($s) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  &__contact {
    @include max("1279px") {
      margin-right: auto;
    }
    @include max($s) {
      position: relative;
      margin-right: 0px;
    }
  }
  &__social {
    @include max($s) {
      // display: none;
      position: absolute;
      top: 0px;
      right: 20px;
    }
    &-fb {
      margin-right: 15px;
    }
    &-vk {
      color: #fff;
      fill: #fff;
    }
  }
  &__subtitle {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 20px;
    @include max($s) {
      font-size: 16px;
    }
  }
  &__help {
    &-item:not(:last-child) {
      margin-bottom: 10px;
    }
    &-list {
      @include max($s) {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
        }
      }
    }
    &-mobleft {
      @include max($s) {
        width: 50%;
      }
    }
  }
  &__showroom {
    @include max($s) {
      margin-top: 30px;
    }
    &-address {
      margin-bottom: 10px;
      @include max($s) {
        width: 50%;
      }
    }
    &-info {
      @include max($s) {
        display: flex;
        p {
          width: 50%;
        }
      }
    }
  }
  &__map {
    @include max($s) {
      margin-top: 30px;
    }
    @include max("1279px") {
      display: none;
    }
  }

  .rohe {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    @include max($s) {
      margin-top: 40px;
    }
    &__logo {
      width: 53px;
      height: 20px;
    }
    &__text {
      margin-left: 18px;

      font-weight: 500;
      font-size: 15px;
      line-height: 12px;

      a {
        text-decoration: underline;
      }
    }
  }
}
