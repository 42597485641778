.modal-wrp {
  z-index: 5000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal-window {
  z-index: 101;
  width: 500px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 2px;
  color: $n_darkest;
  background-color: white;
  // height: 90%;
  max-height: 100%;
  overflow: hidden;

  @include max($s) {
    width: 100%;
    padding: 20px 12px;
  }

  &--size {
    width: 800px;
    @include max($t) {
      width: 100%;
    }
  }

  &_advice {
    @include max($s) {
      height: calc(100% - 50px);
      position: absolute;
      bottom: 0px;
    }
  }

  &_max {
    width: auto;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $b_light;
  }
  &__answer {
    margin-top: 40px;
    p {
      margin-bottom: 10px;
    }
    label.radio {
      margin-right: 20px;
    }
  }
  &__radio-label {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__checkbox-input {
    margin-right: 15px;
  }
  &__btn {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
  }
  .simplebar-track {
    right: -13px;
    pointer-events: all;
  }
}

.red-bolt {
  color: $r_red;
  font-weight: 600;
}

.center-align {
  text-align: center;
}
