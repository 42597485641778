.categlist {
  background-color: $n_darkest;
  position: relative;
  color: white;
  @include max($s) {
    display: none;
  }
  &__img-wrp {
    position: absolute;
    width: 340px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    @include max(1365px) {
      display: none;
    }
  }
  &__img {
    position: sticky;
    top: 100px;
    opacity: 0;
    transition: all 0.3s;
    &.active {
      opacity: 1;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__str {
    padding: {
      top: 20px;
      bottom: 15px;
    }
    width: 100%;
    margin: 0 auto;
    transition: all 0.2s linear;
    box-sizing: border-box;
    border-bottom: 1px solid $n_dark;
    &:hover {
      background-color: $b_indigo;
    }
    @include max($m) {
      padding: {
        top: 30px;
        bottom: 30px;
      }
    }
    .h3 {
      vertical-align: top;
      @include max($m) {
        margin-bottom: 20px;
      }
    }
    &-list {
      display: flex;

      @include max($m) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      margin-right: 25px;
      &-item {
        margin-top: 15px;
        &:hover {
          color: white;
        }
        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
    &-link {
      padding-right: 25px;
    }
  }
}
