.prod-review-block {
  margin-bottom: 100px;

  @include max($s) {
    margin-bottom: 50px;
  }
}

.prod-review {
  .prod-review__stats-btn {
    @include min($s) {
      display: none;
    }
  }

  &.prod-review_empty {
    .prod-review__head {
      border-bottom: none;
      padding-bottom: 0px;

      img {
        margin-left: 10px;
      }
    }

    .prod-review__stats-btn {
      display: block;
      margin: 0px;
      padding: 0px;
      border: none;
      @include min($s) {
        width: 58%;
      }
    }
  }

  &__head {
    display: flex;

    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8f1fd;

    @include max($s) {
      justify-content: space-between;
    }

    .prod-review__stat-head {
      display: none;

      @include max($s) {
        display: flex;
      }
    }
  }

  &__title {
    span {
      color: $n_grey;
      margin-left: 10px;
    }
  }

  &__star {
    width: 24px;
    height: 24px;

    .btn {
      margin-bottom: 10px;
    }
  }

  &__info-message {
    display: flex;
    align-items: stretch;

    i {
      font-size: 20px;
      margin-right: 10px;
      align-self: flex-start;
    }

    p {
      display: flex;
      align-items: flex-end;
      white-space: pre-wrap;
    }
  }

  &__stat-head {
    display: flex;
    align-items: center;
    .prod-review__star {
      margin-right: 10px;
    }
  }

  &__star-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    p {
      flex-shrink: 0;
    }
  }

  &__count {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__stats {
    position: sticky;
    top: 80px;
    @include max($s) {
      display: none;
    }
  }

  &__stats-btn {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e8f1fd;

    @include max($s) {
      padding-top: 0px;
      margin-top: 0px;
      border-top: none;

      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e8f1fd;
    }
  }

  &__star-info {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e8f1fd;
  }

  &__count-line {
    width: 70%;
    position: relative;
    height: 6px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: auto;
    margin-right: 8px;

    &_back {
      width: 100%;
      height: 100%;
      background-color: #c9c9de;
    }

    &_info {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      background-color: #f2c94c;
      border-radius: 10px;
    }
  }

  &__create {
    margin-bottom: 60px;

    .h3 {
      padding-bottom: 20px;
      border-bottom: 1px solid #e8f1fd;
      margin-bottom: 20px;
    }

    textarea {
      height: 100px;
    }

    .prod-review__star {
      width: 36px;
      height: 36px;
      margin-right: 10px;
      cursor: pointer;
    }

    //.prod-review__input-buttons {
    //  .btn.deactive {
    //    background-color: #c9c9de !important;
    //    color: #fff !important;
    //  }
    //}
  }

  &__input-block {
    margin-bottom: 20px;
    &:first-of-type {
      margin-bottom: 30px;
    }
  }

  &__stars-block {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .prod-review__stars {
      margin-right: 10px;
      line-height: 20.7px;
    }

    .prod-review__date {
      line-height: 20.7px;
      color: $n_grey;
      @include max($s) {
        margin-left: auto;
      }
    }
  }

  &__text {
    margin-bottom: 10px;

    span {
      font-weight: 500;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  &__user-name {
    font-weight: 500;
  }

  &__element {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8f1fd;

    &:last-of-type {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
  &__see-more {
    .btn {
      background-color: #e8f1fd;
      color: #096ade;
      border-radius: 6px;
    }

    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e8f1fd;

    @include max($s) {
      border-top: none;
      padding-top: 0px;
    }
  }
}
