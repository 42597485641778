.navigation {
  background-color: $n_darkest;
  font-family: "Travels", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 99;
  color: white;
  @include max($t10) {
    display: none;
  }
  &.show-border {
    border-bottom: 1px solid #222736;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max($s) {
      flex-direction: column-reverse;
    }
  }
  &__left {
    width: 100%;
    padding-right: 20px;
    &-logo {
      text-decoration: none !important;

      i {
        font-size: 0px;
        transition: all 0.2s linear;
        &::before {
          transition: all 0.2s;
        }
        &:hover {
          &::before {
            color: $b_indigo;
          }
        }
      }
      transition: all 0.2s linear;
    }
    @include max($s) {
      width: 100%;
    }
    &-list {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      @include max($s) {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    &-item {
      cursor: pointer;
      transition: all 0.3s linear;
      box-sizing: border-box;
      position: relative;
      margin-right: 20px;
      &--wrp {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      img {
        height: 12px;
      }
      div > span {
        padding-top: 11px;
        padding-bottom: 11px;
        &:hover {
          text-decoration: underline;
        }
      }

      &__btn {
        text-align: center;
        min-width: 130px;
        background-color: $b_indigo;
        border-radius: 2px;
        transition: all 0.2s;
        text-decoration: none !important;

        &:hover {
          text-decoration: none !important;
          background-color: $b_violet;
        }
      }

      &-link {
        &.special {
          color: $r_red;
          font-weight: 700;
        }

        &.icon-gift {
          font-size: 24px;

          &:hover {
            text-decoration: none;
          }
        }
      }
      &-group {
        position: relative;
      }
    }
    &-dropdown {
      &--eq {
        left: 0;
      }
      display: flex;
      z-index: 100;
      font-size: 16px;
      line-height: 25.2px;
      color: $n_darkest;
      background-color: white;
      border-radius: 2px;
      box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);
      padding: 20px;
      position: absolute;
      top: 50px;
      left: -15px;
      opacity: 0;
      visibility: hidden;
      transition: transform 0.2s, opacity 0.2s, visibility 0.2s;
      transform: translateY(-8px);
      min-width: 200px;
      &-link {
        font-weight: 600;
        margin-bottom: 20px;
        transition: all 0.3s linear;
        text-align: left;
        font-size: 15px;
        line-height: 1.5;

        &.active {
          color: $b_indigo;
        }
      }
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
      &-container {
        display: flex;
      }
      &-main {
        padding-right: 40px;
        //border-right: 1px solid $b_light;
        display: flex;
        flex-direction: column;
        min-width: 175px;
      }
      &-submenu {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-width: 200px;
        width: 200px;

        padding-right: 20px;
        border-left: 1px solid $b_light;

        transition: all 0.1s linear;
        //overflow: hidden;

        &.disable {
          opacity: 0;
          visibility: hidden;
          min-width: 0px;
          width: 0px;
          padding: 0px;
        }

        & a {
          font-family: "Commons";
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 20px;
          width: 100%;
          &:hover {
            text-decoration: underline;
            color: $b_indigo;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      &--small {
        position: relative;
        min-width: auto;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        border: none;
      }
    }
  }
  &__right {
    position: relative;
    @include max($s) {
      width: 100%;
    }
    &-icon {
      &:hover {
        text-decoration: none;
      }
      & > i {
        font-size: 24px;
      }
      @include max($s) {
        display: none;
      }
      margin-right: 45px;
      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
  &__search {
    width: 100%;
    position: relative;
    &-input {
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
      border: 1px solid #717188;
      padding: 12px;
    }
    &-button {
      position: absolute;
      top: 25%;
      right: 5%;
    }
  }
  &__desktop {
    &-search {
      // position: absolute;
      position: relative;
      // width: calc(100% - 145px);
      width: 100%;
      // min-width: 400px;
      right: 0;
      transition: all 0.4s linear;
    }
    &-form {
      position: relative;
    }
    &-btn {
      position: absolute;
      top: 8px;
      right: 10px;
      & > i {
        color: black;
        font-size: 24px;
      }
    }
  }
  .triangle {
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute; /* Абсолютное позиционирование */
      left: 20px;
      bottom: 20px; /* Положение треугольника */
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }
  &.on-top {
    .navigation__left-logo {
      margin-right: 20px;
      i {
        font-size: 20px;
      }
    }
    .navigation__desktop-search {
      width: calc(100% - 205px);
    }
  }
}

.fake-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0px;
  left: 0px;
  cursor: auto;

  &.active {
    display: block;
  }

  &.cart {
    z-index: 99;
  }
}
