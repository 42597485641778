.red-line {
  height: 30px;
  width: 100%;
  background-color: $r_dark;
  background-image: url("../../img/red-line-d.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include max($s) {
    background-image: url("../../img/red-line-m.png");
  }

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  transition: height 0.2s linear;

  &.deactive {
    // min-height: 0px;
    height: 0px;
  }

  p {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-top: 9px;
    margin-bottom: 7px;
  }

  @include max($s) {
    height: 38px;
    // min-height: 24px;
    // max-height: 38px;

    p {
      margin-top: 6px;
      margin-bottom: 4px;
    }
  }
}
