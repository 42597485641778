.sert-block {
  .input-block {
    height: 100%;
    padding-bottom: 0px;
    textarea {
      height: 160px;
    }
  }

  &__photo {
    @include max($s) {
      order: -1;
      margin-bottom: 30px;
    }
  }

  &__devider {
    width: 100%;
    border-bottom: 1px solid #e8f1fd;
    margin-bottom: 20px;

    @include max($s) {
      display: none;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;

    @include max($s) {
      display: none;
    }
  }

  &__load-photo {
    border: 1px solid $b_light;
    height: 121px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    img {
      width: 100%;
    }

    &_clear {
      img {
        width: 80%;
      }
    }

    @include max($s) {
      height: 160px;
      margin-top: 0px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
  &__btn {
    border-radius: 2px;
    background-color: #f4f9ff;
    width: 100%;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $b_indigo;

    padding: 6px 0px;
    margin-top: 10px;

    &_width {
      width: 200px;

      @include max($s) {
        width: 100%;
      }
    }

    &_delete {
      color: #c5121b;
      background-color: #ffd9db;
    }

    @include max($s) {
      padding-top: 9.5px;
      padding-bottom: 9.5px;

      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
    }
  }

  &__preview {
    position: fixed;
    z-index: 100000;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    .gift-page {
      height: 100%;
    }
  }

  .prod-descr__cart {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 20px;

    .prod-descr__cart-btns {
      .btn_secondary {
        margin-right: 20px;
        @include max($s) {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
