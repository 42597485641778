//Blue
$b_pale: #f4f9ff;
$b_light: #e8f1fd;
$b_sky: #70a4ef;
$b_indigo: #096ade;
$b_violet: #504eed;

//Neutral
$n_light: #fafafd;
$n_middle: #c9c9de;
$n_grey: #717188;
$n_dark: #1e222f;
$n_darkest: #02081e;
$n_black: #070713;

//Red
$r_blash: #ffd9db;
$r_red: #f21d28;
$r_dark: #c5121b;

//Green
$g_green: #219653;

//More
$white: #fff;
$black: #000;
