.profile-bonuses {
  color: $n_darkest;
  padding-top: 50px;
  padding-bottom: 130px;
  @include max($s) {
    padding-bottom: 50px;
    padding-top: 0px;
  }
  &__row {
    @include max($s) {
      flex-direction: column-reverse;
    }
  }
  &__title {
    font-family: "Travels", sans-serif;
    font-weight: 600;
    margin-bottom: 40px;
    @include max($s) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      // border-bottom: 1px solid $b_light;
    }
  }
  &-card {
    width: 100%;
    &:not(:last-child) {
      padding-bottom: 40px;
      border-bottom: 1px solid $b_light;
      margin-bottom: 40px;
      @include max($s) {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
    &__label {
      font-family: "Commons";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      @include max($s) {
        font-weight: 500;
        font-size: 15px;
      }
    }
    &__description {
      display: flex;
      justify-content: space-between;
      @include max($s) {
        display: block;
        .profile-bonuses-card__show {
          margin-top: 10px;
        }
      }
      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #717188;
      }
      .profile-bonuses-card__show {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #70a4ef;
        cursor: pointer;
      }
    }

    &__products {
      padding: 0px 12px;

      box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);
      border-radius: 4px;

      height: 0px;
      max-height: 0px;
      opacity: 0;
      overflow: hidden;

      transition: all 0.2s linear;

      &.show {
        margin-top: 20px;

        height: auto;
        max-height: 1000px;
        opacity: 1;
        overflow-y: auto;
      }
    }
    &__product {
      display: flex;
      box-sizing: content-box;
      padding: 12px 0px;
      border-bottom: 1px solid #e8f1fd;

      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      div {
        overflow: hidden;
        p {
          width: 100%;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:last-of-type {
            color: #24b34c;
          }
        }
      }
    }

    &__top {
      display: flex;
      flex-direction: column;

      justify-content: space-between;
      margin-bottom: 10px;
    }
    &__date {
      color: $n_grey;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;
    }

    &__value {
      cursor: auto;
      margin-left: 10px;
      & > i {
        font-size: 16px;
        margin-left: 6px;
        color: $b_indigo;
      }
      &.success {
        color: $g_green;
      }
      &.danger {
        color: $r_red;
      }
      &.future {
        color: $b_indigo;
      }
    }

    &_status {
      .profile-bonuses-card__date {
        margin-bottom: 0px;
      }

      .profile-bonuses-card__top {
        justify-content: space-between;
        flex-direction: row;
        background: #f4f9ff;

        padding: 20px 25px 25px 20px;

        border: 1px solid #e8f1fd;
        border-radius: 6px;

        min-height: 70px;
        height: 70px;

        @include max($d) {
          height: 75px;
        }

        @include max($t) {
          overflow: hidden;
        }

        .profile-bonuses-card__text {
          max-width: 60%;

          // white-space: pre-line;
          .profile-bonuses-card__label {
            font-family: "Commons";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;

            margin-bottom: 4px;
          }

          .profile-bonuses-card__description {
            display: block;
            p {
              color: inherit;
            }
            p:first-child {
              @include max($t) {
                display: none;
              }
            }
          }
        }

        .profile-bonuses-card__right {
          display: flex;
          height: 100%;

          .profile-bonuses-card__arrows {
            position: relative;
            min-height: 100%;
            width: 60px;
            @include max($t) {
              display: none;
            }
            i {
              display: block;
              position: absolute;
              font-size: 30px;
              transform: rotate(-90deg);
              color: inherit;

              &:nth-child(1) {
                right: 10px;
                top: 0px;
              }
              &:nth-child(2) {
                left: 0px;
                top: 25px;
              }
              &:nth-child(3) {
                top: 40px;
                right: 0px;
              }
            }
          }
          .profile-bonuses-card__logo-block {
            height: 100%;
            margin-left: 8px;

            .profile-bonuses-card__logo {
              position: relative;
              width: 110px;
              min-height: 100%;

              .status-pic {
                position: absolute;
                width: 110px;
                height: 110px;
                bottom: 0px;
                right: 0px;

                @include max($t) {
                  width: 120px;
                  height: 120px;
                  bottom: -45px;
                  right: -15px;
                }
              }
            }

            .profile-bonuses-card__shadow {
              height: 12px;
              width: 110px;
              background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(18, 55, 65, 0.16) 0%,
                rgba(0, 0, 0, 0) 100%
              );

              @include max($t) {
                display: none;
              }
            }
          }
        }
      }

      &.success {
        .profile-bonuses-card__label {
          color: $g_green;
        }

        .profile-bonuses-card__arrows {
          color: #8cbcf5;
        }
      }
      &.danger {
        .profile-bonuses-card__label {
          color: $r_red;
        }
        .profile-bonuses-card__arrows {
          color: #ffbcbf;
          transform: rotate(180deg) scaleX(-1);
        }
      }

      &.new {
        @include max($t) {
          margin-bottom: 50px;
        }

        .profile-bonuses-card__top {
          height: 90px;
          position: relative;
          overflow: visible;
          @include max($t) {
            height: 135px;
            padding: 12px;
          }

          .profile-bonuses-card__text {
            max-width: 70%;
            @include max($t) {
              width: 100%;
              max-width: 100%;
            }

            .profile-bonuses-card__description {
              display: block;
              p {
                color: inherit;

                @include max($t) {
                  display: inline;
                }
              }
              p:first-child {
                @include max($t) {
                  display: inline;
                }
              }
              p:last-child {
                display: block;
                @include max($t) {
                  margin-top: 4px;
                }
              }
            }
          }
        }

        .profile-bonuses-card__right {
          .profile-bonuses-card__arrows {
            display: none;
          }

          .profile-bonuses-card__logo-block {
            .profile-bonuses-card__logo {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 160px;
              height: 160px;

              @include max($t) {
                width: 140px;
                height: 140px;

                top: auto;
                bottom: -80px;

                transform: rotate(15deg);
              }

              .status-pic {
                position: static;
                width: 160px;
                height: 160px;
                top: 0px;
                right: 0px;
                bottom: auto;

                @include max($t) {
                  width: 140px;
                  height: 140px;

                  top: auto;
                  bottom: -80px;
                }
              }
            }

            .profile-bonuses-card__shadow {
              display: none;
            }
          }
        }
      }
    }

    &_temporary {
      .profile-bonuses-card__top {
        position: relative;
        overflow: hidden;
      }

      &.plus {
        color: $b_indigo;
      }
      &.minus {
        color: $r_dark;
      }
      .profile-bonuses-card__description {
        color: $black;
      }
      .profile-bonuses-card__right {
        .i-bonuses {
          font-size: 139px;
          &::before {
            position: relative;
            vertical-align: top;
            right: -15px;
            top: -10px;

            @include max($t) {
              right: -25px;
              top: -5px;
            }
          }
        }
      }
    }
  }
  &__info {
    padding-left: 40px;
    @include max($s) {
      //не смогла сделать фон
      // background-color: $b_light;
      padding-left: 10px;
      // padding-bottom: 50px;
      padding-top: 50px;
      // margin-bottom: 40px;
    }
    &-title {
      font-weight: 600;
      font-family: "Travels", sans-serif;
      @include max($s) {
        font-size: 18px;
        line-height: 25.2px;
      }
    }
    &-amount {
      // justify-content: space-between;
      padding: 30px 0px;
      margin: 30px 0px;
      border-bottom: 1px solid #e8f1fd;
      border-top: 1px solid #e8f1fd;
      &-title {
        line-height: 20.7px;
        @include max($s) {
          font-size: 18px;
        }
      }

      &-bonus {
        display: flex;
        align-items: center;
      }

      &-value {
        font-family: "Travels", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
        margin-left: 10px;

        & > i {
          color: $b_sky;
        }
      }
      &-date {
        font-family: "Travels", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
        margin-left: auto;
        color: #717188;

        &.danger {
          color: $r_red;
        }
      }
    }

    &-temporary {
      display: flex;
      margin-top: 30px;

      width: 100%;
      & > div {
        margin-right: 5px;
        width: 100%;

        &:first-of-type {
          .line {
            border-radius: 10px 0px 0px 10px;
          }
        }
        &:last-of-type {
          margin-right: 0px;
          .line {
            border-radius: 0px 10px 10px 0px;
          }
        }

        .line {
          height: 6px;
          width: 100%;

          margin-bottom: 12px;

          background-color: $b_indigo;
        }

        .value {
          font-family: "Travels";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          margin-bottom: 2px;
        }

        .date {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;

          color: $n_grey;
        }
      }

      .burn {
        .line {
          background-color: $r_dark;
        }
        .date {
          color: $r_dark;
        }
      }
    }

    &-achieve {
      font-size: 15px;
      line-height: 19.6px;
      font-weight: 500;
      color: $n_grey;
      // margin-bottom: 20px;
      margin-top: 10px;
    }
    &-nextlvl {
      font-size: 15px;
      line-height: 19.6px;
      color: $n_grey;

      p {
        font-size: inherit;
        line-height: inherit;
      }

      a {
        display: none;

        @include max($s) {
          display: block;
          margin-top: 10px;
          color: $b_sky;
        }
      }
    }
    &-about {
      font-size: 15px;
      line-height: 19.6px;
      margin-bottom: 20px;

      padding: 12px 14px;

      background: #fafafd;

      border: 1px solid #e8f1fd;
      border-radius: 2px;

      overflow: hidden;

      a {
        color: $b_sky;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;

        margin-bottom: 10px;
      }
      @include max($s) {
        display: none;
      }
    }
  }
}
