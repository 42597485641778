[class^="i-"],
[class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  // speak: never;
  font-style: normal;
  line-height: 1;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-p:before {
  content: "\e922";
}
.i-bonuses:before {
  content: "\e901";
  vertical-align: middle;
}
.i-card:before {
  content: "\e902";
}
.i-card2:before {
  content: "\e903";
}
.i-cart-off:before {
  content: "\e904";
}
.i-cart-on:before {
  content: "\e905";
}
.i-check:before {
  content: "\e909";
}
.i-close:before {
  content: "\e90a";
}
.i-delivery:before {
  content: "\e90b";
}
.i-down:before {
  content: "\e90c";
}
.i-filter:before {
  content: "\e90d";
}
.i-house:before {
  content: "\e90e";
}
.i-info:before {
  content: "\e90f";
}
.i-license:before {
  content: "\e910";
}
.i-link:before {
  content: "\e911";
}
.i-logo:before {
  content: "\e912";
  color: #fff;
}
.i-menu:before {
  content: "\e913";
}
.i-minus:before {
  content: "\e914";
}
.i-pag-left:before {
  content: "\e915";
}
.i-pag-right:before {
  content: "\e916";
}
.i-plus:before {
  content: "\e917";
}
.i-radio-elipse-empty:before {
  content: "\e918";
}
.i-radio-elipse-full:before {
  content: "\e919";
  color: #096ade;
}
.i-sale:before {
  content: "\e91a";
}
.i-search:before {
  content: "\e91b";
}
.i-settings:before {
  content: "\e91c";
}
.i-support:before {
  content: "\e91d";
}
.i-toggle-off:before {
  content: "\e91e";
}
.i-toggle-on .path1:before {
  content: "\e91f";
  color: rgb(9, 106, 222);
}
.i-toggle-on .path2:before {
  content: "\e920";
  margin-left: -1.03125em;
  color: rgb(255, 255, 255);
}
.i-user:before {
  content: "\e921";
}
.i-arrow:before {
  content: "\e900";
}
