.filter {
  padding-right: 90px;
  color: #222736;
  font-weight: 500;
  font-size: 15px;
  line-height: 19.6px;
  @include max($s) {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 100vw;
    height: 100%;
    z-index: 9999;
    padding: 0px;
    overflow-y: auto;
  }
  @include max($m) {
    padding-right: 0px;
  }

  &__body {
    @include max($s) {
      height: calc(100% - 190px);
      overflow: auto;
    }
  }
  &__item {
    @include max($s) {
      &_m-line {
        .filter__item-head {
          .filter__item-title {
            width: auto;
          }
        }
      }

      &_price {
        .i-down {
          display: none;
        }
        .filter__item-price {
          display: block;
          position: static !important;
          opacity: 1 !important;
          visibility: visible !important;
          width: 100% !important;
          height: 100px !important;
          padding: 0px !important;
          margin-top: 20px !important;
        }
      }
    }

    &-slider {
      background-color: $b_light;
      border-radius: 2px;
      .rc-slider {
        width: calc(100% - 30px);
        margin-left: auto;
        margin-right: auto;
        height: 4px;
        padding: 0;
      }
    }
    &-year {
      .filter__item-slider {
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .rc-slider-mark-text {
        font-size: 15px;
        font-family: "Commons";
      }
    }
    &-title {
      font-size: 15px;
      font-weight: 600;

      @include max($s) {
        flex-shrink: 0;
        margin-right: 60px;
      }
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid $b_light;
      margin-bottom: 20px;
    }

    &--cat {
      padding-bottom: 30px !important;
      margin-bottom: 50px !important;
      border-bottom: 1px solid $n_middle !important;
      @include max($s) {
        display: none;
      }
      .cat-item {
        margin-bottom: 10px;
      }
    }

    &.active {
      .filter__item-head {
        .filter__item-title,
        label {
          color: $b_indigo;
        }
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      width: 100%;
      flex-basis: 0;

      cursor: pointer;
      font-weight: 600;
      .filter__item-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 0%;

        & p {
          display: none;

          @include max($s) {
            display: block;
          }
          font-size: 15px;
          line-height: 15px;
          font-weight: 500;
          color: $n_grey;
          vertical-align: bottom;

          margin-right: 10px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          flex-grow: 0;
        }

        & i {
          transition: all 0.3s linear;
          @include max($s) {
            transform: rotate(-90deg);
            vertical-align: top;
          }
        }
      }

      &.open {
        & ~ div {
          padding-top: 10px;
          height: 100%;
          opacity: 1;
          visibility: visible;
          @include max($s) {
            display: block;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 0px;

            // padding-top: 80px;
            // padding-bottom: 100px;
          }
        }
        & i {
          transform: rotate(180deg);
          @include max($s) {
            transform: rotate(-90deg);
          }
        }
      }
    }
    &-num {
      font-weight: 500;
      color: $n_middle;
      margin-left: 10px;
    }
    &-dropdown {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      padding-left: 20px;
      &-head {
        font-weight: 500;
      }
      .filter__item-title {
        font-weight: 500;
      }
    }
    &-subdropdown {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      padding-left: 20px;
      &-title {
        margin-bottom: 10px;
        &:hover {
          text-decoration: none;
        }
        &.active {
          color: #096ade;
          cursor: default;
        }
      }

      a {
        display: block;
      }
    }
    &-choice {
      display: flex;
      flex-direction: column;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;

      @include max($s) {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: calc(100vw - 24px);
        height: 100%;
        z-index: 9999;
        padding: 0px 12px;
        overflow-y: hidden;
      }
    }
    &-gender {
      &-var {
        display: flex;
        align-items: center;

        @include max($s) {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;

          padding-bottom: 10px;
          margin-bottom: 10px;

          border-bottom: 1px solid #e8f1fd;

          overflow: hidden;
          width: 100%;
          box-sizing: border-box;

          &:last-child {
            border-bottom: none;
          }
        }

        & > input {
          transform: translateY(-2px);
        }
        & > label {
          padding-left: 10px;
          @include max($s) {
            padding-left: 0px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &-brand {
      &-search {
        border: none;
        border-bottom: 1px solid $n_middle;
        width: 100%;
        margin-bottom: 10px;
        &::placeholder {
          color: $n_middle;
        }
        @include max($s) {
          border-top: 0px !important;
          border-left: 0px !important;
          border-right: 0px !important;
          padding: 0px !important;

          margin-bottom: 20px;
        }
      }
      &-var {
        display: flex;
        align-items: flex-start;
        @include max($s) {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;

          padding-bottom: 10px;
          margin-bottom: 10px;
          padding-right: 10px;

          border-bottom: 1px solid #e8f1fd;

          overflow: hidden;
          width: 100%;
          box-sizing: border-box;

          &:last-child {
            border-bottom: none;
          }
        }
        & > input {
          transform: translateY(2px);
          flex-shrink: 0;
        }
        & > label {
          padding-left: 10px;
          @include max($s) {
            padding-left: 0px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &-size {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      & :first-child {
        .filter__item-size-title {
          margin-top: 0;
        }
      }
      @include max($s) {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: calc(100vw - 40px);
        height: 100%;
        z-index: 9999;
        padding: 0px 20px;
        overflow-y: hidden;
      }
      &-title {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 15px;
        color: $n_grey;
      }
      &-vars {
        display: flex;
        flex-wrap: wrap;
        & > div {
          cursor: pointer;
          border: 1px solid $n_middle;
          line-height: 1.2;
          border-radius: 2px;
          box-sizing: border-box;
          text-align: center;
          vertical-align: middle;
          padding: 4px 10px;
          width: 56px;
          height: 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          transition: all 0.3s linear;
          &.check {
            border: 1px solid $b_indigo;
          }

          & > p {
            font-size: inherit;
            font-family: inherit;
            height: 100%;
            line-height: inherit;
          }
        }
      }
      &-none {
        margin-top: 10px;
        display: flex;
        align-items: center;
        & > input {
          transform: translateY(-2px);
        }
        & > label {
          padding-left: 10px;
        }
      }
    }
    &-words {
      &-var {
        display: flex;
        align-items: center;
        & > input {
          transform: translateY(-2px);
        }
        & > label {
          padding-left: 10px;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &-price {
      &-count {
        display: flex;
        text-align: center;
        margin-bottom: 25px;
      }
      &-dash {
        width: 11px;
        height: 1px;
        background-color: $n_middle;
        align-self: center;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    @include max($s) {
      padding: 20px 20px;
      margin-bottom: 0px !important;

      // &:first-of-type {
      //   padding-top: 80px;
      // }
    }
  }
  &__button {
    width: 100%;
    background-color: $n_light;
    color: $b_indigo;
    margin-bottom: 20px;
    text-align: center;
    padding: 12px;
    font-weight: 600;
    line-height: 16px;
    font-size: 16px;
    @include max($s) {
      display: none;
    }
  }
  label.filter__item-title {
    width: 100%;
  }

  .filter__buttons {
    display: none;
    justify-content: space-between;
    background-color: #fff;

    width: calc(100% - 40px);
    padding: 20px 20px;
    @include max($s) {
      display: flex;

      .btn {
        padding: 17px 0px;
      }

      .btn_secondary.deactive {
        color: $n_grey !important;
      }
    }
  }

  &.close {
    @include max($s) {
      display: none;
    }
  }
}

.mobile-filters__top {
  display: none;
  // position: fixed;
  top: 0px;
  left: 0px;
  height: 60px;
  z-index: 9999999;
  width: calc(100% - 40px);
  padding: 0px 20px;
  background-color: #fff;

  @include max($s) {
    display: flex;
  }
}
