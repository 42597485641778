.mobile-menu {
  position: fixed;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  top: 42px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $n_darkest;
  height: calc(100vh - 62px);
  color: #fff;

  &.mt-red {
    top: 80px;
  }

  &__form {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    &-btn {
      position: absolute;
      right: 3%;
      top: 15%;
      & > i::before {
        font-size: 30px;
        color: $n_grey;
      }
    }
  }
  &__input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid $n_grey;
    color: #fff;
    &::placeholder {
      font-size: 18px;
    }
  }
  &__list {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    font-family: "Travels", sans-serif;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $n_grey;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > i {
        transform: rotate(-90deg);
      }
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
    &-item:not(:last-child) {
      margin-bottom: 20px;
    }
    &-link {
      &.special {
        color: $r_red;
      }
    }
  }
  &__btn {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
  }
  &__phone {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 17px 0;
    background-color: $n_dark;
    font-family: "Travels", sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  &__help {
    font-size: 22px;
    line-height: 25.2px;
    color: $n_grey;
    padding-bottom: 100px;
    &-item:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.mob-menu-open-category {
  &__title {
    font-family: "Travels", sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 43px;
  }
  &__list {
    font-weight: 400;
    font-size: 22px;
    line-height: 25.2px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & > i {
      transform: rotate(-90deg);
    }

    a {
      width: 100%;
    }
  }
}
