.gift-page {
  background-image: url("../../../img/back-for-gift.png");
  background-size: cover;
  background-color: #1b1f2b;

  &.scroll {
    overflow-y: auto;
  }

  &__close {
    position: absolute;

    top: 30px;
    right: 75px;

    @include max($s) {
      position: static;
      margin-left: auto;
      margin-top: 25px;
      margin-right: 25px;
    }

    i {
      color: #fff;
      font-size: 25px;
    }
  }

  &__pic-block {
    @include max($s) {
      order: 2;
    }
  }

  &__pic {
    // width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($s) {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }

  &__pic-position {
    position: relative;
    width: 100%;
  }

  &__pic-text {
    position: absolute;
    font-family: "Druk Cyr", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36.6px;
    line-height: 36.6px;
    color: #fff;

    transform: rotate(-13.7deg);
  }

  &__message {
    margin-top: 100px;
    margin-bottom: 100px;

    padding: 20px;

    background-color: #141c36;

    @include max($s) {
      margin-top: 30px;
      margin-bottom: 50px;

      padding: 10px;
    }
  }

  &__text {
    color: #fff;
    margin-bottom: 20px;

    & > span {
      display: block;
      font-family: "Travels", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.2px;
      margin-bottom: 10px;
    }
  }

  &__image {
    width: 100%;

    margin-bottom: 40px;
  }

  &__promo-block {
    display: flex;

    margin-bottom: 20px;

    @include max($t) {
      flex-direction: column;
    }

    button {
      margin-left: 20px;

      @include max($t) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }

  &__promo {
    width: 100%;
    padding: 17.5px 20px 11.5px;
    background-color: #fff;
    color: #717188;
    box-sizing: border-box;
  }

  &__devider {
    width: 100%;
    height: 1px;
    background-color: #222736;
    margin-bottom: 20px;
  }

  &__desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #717188;
  }
}
