//Сброс ненужных стилей

*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  font: inherit;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

ul {
  list-style-type: none;
}

table {
  border-spacing: 0;
}

label {
  cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}
