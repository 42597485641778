.header {
  background-color: $n_black;
  color: $n_grey;
  font-family: "Commons", sans-serif;
  font-size: 16px;
  line-height: 16.8px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $n_dark;
  @include max($t10) {
    background-color: $n_darkest;
    position: sticky;
    padding-bottom: 10px;
    padding-top: 11px;
    top: -1px;
    z-index: 999;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 29px;
  }
  &__left {
    display: flex;
    align-items: center;
    @include max($t10) {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
    &-logo {
      display: flex;
      margin-right: 20px;
      img {
        height: 12px;
      }
      @include max($t10) {
        margin-right: 0;
        & > i::before {
          color: $b_indigo;
        }
      }
      & > i {
        font-size: 18px;
      }
    }
    &-burger {
      display: none;
      @include max($t10) {
        display: inline-block;
        & > i::before {
          color: white;
          font-size: 30px;
        }

        & > .i-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &-list {
      display: flex;
      align-items: center;
      padding-top: 4px;
      @include max($t10) {
        display: none;
      }
    }
    &-item:not(:last-child) {
      margin-right: 20px;
    }
    &-item {
      transition: all 0.3s linear;
    }
    &-item:hover {
      a {
        color: white;
      }
      text-decoration: underline;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    @include max($t10) {
      display: none;
    }
    &-phone {
      padding-left: 24px;
      font-size: 18px;
      line-height: 20.7px;
      color: $white;
      font-family: "Commons", sans-serif;

      &:hover {
        color: #fff;
      }
    }
    .btn {
      @include max(1095px) {
        display: none;
      }
      font-size: 14px;
      font-weight: 500;
      padding: {
        top: 6px;
        bottom: 6px;
      }
    }
  }
  &__cart {
    display: none;
    @include max($t10) {
      display: block;
      position: relative;
      & > i::before {
        color: white;
        font-size: 30px;
      }
    }
  }
}
