@import "./Advantages/advantages.scss";
@import "./CatalogSwiper/catalog-swiper.scss";
@import "./BrandSwiper/brand-swiper.scss";
@import "./CategList/categ-list.scss";
@import "./Hero/hero.scss";
@import "./MobileCategList/mobile-categ-list.scss";
@import "./GiftPage/gitf-page.scss";

.order {
  @include max($s) {
    display: flex;
    flex-direction: column;
  }
  &__item:nth-of-type(1) {
    order: 3;
  }
  &__item:nth-of-type(2) {
    order: -1;
  }
  &__item:nth-of-type(1) {
    order: 2;
  }
}
