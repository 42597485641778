.profile-header {
  padding-top: 50px;
  padding-bottom: 100px;
  background-image: url(../../img/mailing-bg.png?v2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Travels", sans-serif;
  color: #fff;
  &.flex {
    display: flex;
  }
  &.no-margin {
    margin-bottom: 0;
  }
  &.slim {
    padding: 50px 0px;
    .profile-header__top {
      margin-bottom: 0px;
      @include max($s) {
        padding-bottom: 30px;
      }
    }
  }
  @include max($s) {
    padding-bottom: 81px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
    @include max($s) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    &-back {
      & > a {
        display: flex;
        align-items: flex-end;
        &:hover {
          text-decoration: none;
          p {
            text-decoration: underline;
          }
        }
      }
      & i {
        font-size: 24px;
        transform: rotate(-90deg);
        margin-right: 10px;
        display: inline-block;
      }
      @include max($s) {
        margin-bottom: 32px;
      }
    }
  }
  &__hello {
    color: inherit;
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    @include max($s) {
      font-size: 22px;
      line-height: 30.8px;
      font-weight: 500;

      margin-bottom: 60px;
    }
  }
  &__status {
    display: flex;
    @include max($s) {
      width: 100%;
      flex-direction: column;
      margin-top: -40px;
    }
    &-img {
      margin-right: 30px;
      @include max($s) {
        margin-bottom: 80px;
        margin-right: 0;
        align-self: flex-end;
      }
    }
    &-info {
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name {
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 600;
    }
    &-sale {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
    }
    &-next {
      font-size: 15px;
      line-height: 19.6px;
      font-weight: 500;
      color: $n_grey;
      font-family: "Commons", sans-serif;
    }
  }
  &__nav {
    width: 100%;
    display: flex;
    @include max($s) {
      flex-direction: column;
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
      @include max($s) {
        width: 100%;
        justify-content: flex-start;
      }
    }
    &-item {
      &:not(:last-child) {
        margin-right: 55px;
        @include max($s) {
          margin-right: 30px;
        }
      }
      &:last-child {
        @include max($s) {
          margin-left: auto;
        }
      }
      & i {
        font-size: 24px;
        margin-right: 10px;
        @include max($s) {
          margin-right: 0;
        }
      }
    }
    &-link {
      transition: all 0.3s linear;
      font-weight: 500;
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: none;
        span {
          text-decoration: underline;
        }
      }
      &.active {
        color: $b_sky;
        font-weight: 400;
      }
      & > span {
        @include max($s) {
          display: none;
        }
      }

      i {
        &::before {
          vertical-align: auto;
        }
      }
    }
  }
}
