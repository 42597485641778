.profile-open-order {
  padding-top: 50px;
  &__num {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 10px;
    @include max($s) {
      font-weight: 500;
    }
  }
  &__status {
    margin-bottom: 40px;
    @include max($s) {
      margin-bottom: 30px;
    }
  }
  color: $n_dark;
  &__info {
    display: flex;
    align-items: center;
    @include max($s) {
      flex-direction: column;
      align-items: flex-start;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    & > p:not(:last-child) {
      color: $n_grey;
      margin-right: 10px;
    }
  }
  &__btn {
    margin-top: 20px;
    margin-bottom: 120px;
    @include max($s) {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  &__cards {
    padding-top: 60px;
    margin-top: 60px;
    margin-bottom: 100px;
    border-top: 1px solid #e8f1fd;
    @include max($s) {
      padding-top: 50px;
      border-top: 1px solid $b_light;
      margin-bottom: 50px;
    }
  }

  &__delivery-part {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .cart__order {
    margin-top: 0px;
    padding-top: 0px;
    border-top: none;
  }
}
