.prod-list {
  padding-bottom: 30px;
  padding-top: 30px;
  @include max($s) {
    padding-bottom: 20px;
    padding-top: 20px;

    a.p {
      font-size: 13px;
      line-height: 18.2px;
      font-weight: 500;
    }
  }

  &__mobile-price {
    display: none;
    @include max($s) {
      display: block;

      .prod-card__comparison {
        & > div {
          display: flex;
        }
      }
    }
  }

  &__har {
    max-width: 70%;
    margin-top: 30px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s linear;

    @include max($s) {
      max-width: 100%;
      margin-top: 20px;
    }

    ul {
      li {
        margin-bottom: 5px;
        p {
          @include max($s) {
            font-size: 12px;
            line-height: 13px;
          }
        }
        p:first-child {
          flex-shrink: 0;
        }
        p:last-child {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    &.addit {
      margin-top: 5px;
    }

    &.active {
      max-height: 350px;
      overflow: auto;
    }
  }

  .prod-list__img {
    height: 100%;
    border-right: #e8f1fd 1px solid;
    @include max($s) {
      border-right: none;
    }

    .prod-list__bages {
      display: none;
      @include max($s) {
        display: block;
      }
      position: absolute;
      bottom: 0px;
      left: 0px;

      .prod-card__news {
        margin-top: 0px;
        margin-right: 5px;
      }
    }
  }

  .prod-card__news {
    line-height: 15px;
    font-size: 15px;
    margin-top: 10px;
  }
  .prod-list__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    font-size: 20px;
    line-height: 23px;

    .prod-card__comparison {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 23px;
      }
      .prod-card__sale {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
      }
    }

    .prod-card__price {
      margin-left: 0px !important;
      margin-bottom: 20px;
      font-size: inherit;
    }
  }

  .prod-list__har-btn {
    display: flex;
    color: $b_sky;
    align-items: center;
    @include max($s) {
      margin-top: 15px;
      font-size: 12px;
      line-height: 13px;
      font-weight: 400;
    }

    i {
      transition: transform 0.3s linear;
    }

    p {
      margin-right: 5px;
      @include max($s) {
        font-size: 12px;
        line-height: 13px;
        font-weight: 400;
      }
    }

    &:hover {
      text-decoration: none !important;
      p {
        text-decoration: underline;
      }
    }

    &.active {
      i {
        transform: rotate(180deg);
      }
    }
  }

  .prod-list__btn-buy {
    position: relative;
    display: flex;
    width: 100%;

    .input--amount {
      position: absolute;
      left: 0%;
      margin: 0px;
      padding-bottom: 14px;
      transition: all 0.3s linear;
      padding-top: 14px;

      &.active {
        right: 100%;
        left: auto;
      }

      .input--amount__input {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 18px;
      }

      .prod-descr__cart-amount-minus {
        max-width: 0px;
        overflow: hidden;
        margin-right: -20px;

        transition: all 0.3s linear;

        &.active {
          max-width: 50px;
          margin-right: 0px;
        }
      }
    }

    .btn {
      z-index: 2;
    }
  }
}

.prod-line-col {
  border-bottom: #e8f1fd 1px solid;

  &:last-of-type {
    border-bottom: none;
  }
}
