.advantages {
  background-color: $b_light;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  @include max($s) {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  &__list {
    @include max($s) {
      flex-direction: column;
    }
  }
  &__item {
    @include max($s) {
      max-width: 100%;
    }
  }
  &__card {
    @include max($s) {
      margin-bottom: 20px;
    }
    &-head {
      & > i {
        font-size: 68px;
        color: black;
        margin-bottom: 10px;
        @include max($s) {
          font-size: 26px;
          margin-right: 10px;
        }
      }
      @include max($s) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-arrow {
      display: none;
      margin-left: auto;
      transition: all 0.3s linear;
      @include max($s) {
        display: block;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
    &-title {
      margin-bottom: 10px;
      color: $n_darkest;
      @include max($s) {
        font-size: 18px;
        line-height: 25.2px;
        margin-bottom: 0;
      }
    }

    &-text {
      color: $n_dark;
      max-width: 400px;

      @include max($s) {
        transition: all 0.3s linear;
        height: 0;
        opacity: 0;
        visibility: hidden;
        padding-top: 10px;
        padding-left: 33px;
        &.open {
          height: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
      // @include max($t10) {
      //   font-size: 15px;
      //   line-height: 19.6px;
      // }

      // p {
      //   font-size: ;
      //   line-height: unset;
      // }
    }
    &-link {
      color: $b_sky;
    }
  }
}
