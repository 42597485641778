.catalog-swiper {
  @include max($s) {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
  }
  // position: absolute;
  // margin-top: -100px;
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    // overflow: hidden;
  }
  .slick-list {
    margin: 0 -10px;
  }

  .slick-slide {
    height: auto;
    & > div {
      height: 100%;
      padding: 0 10px;
      & > div {
        height: 100%;
      }
    }
  }
  &__card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    // height: 305px;
    border-radius: 2px;
    background-color: $n_light;
    padding: 20px;
    color: $n_dark;
    transition: all 0.2s;
    @include max($s) {
      border-radius: 100%;
      width: 100px;
      height: 100px;
      position: relative;
      margin-bottom: 10px;
      margin-right: 0px;
      // margin-right: 20px;
    }
    @include max($m) {
      font-size: 18px;
      line-height: 25px;
      // margin-right: 20px;
    }

    &.active {
      background-color: $b_indigo;
      color: white;
      .catalog-swiper__card-title {
        color: white;
      }
      .catalog-swiper__card-link {
        color: white;
      }
    }
    &-title {
      margin-bottom: 20px;
      transition: all 0.2s;
      &:hover {
        color: $b_indigo;
        text-decoration: underline;
      }
      @include max($s) {
        width: 108px;
        font-family: "Commons", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
    &-list {
      @include max($s) {
        display: none;
      }
    }
    &-item:not(:last-child) {
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  .slick-next {
    top: 110% !important;
    right: 15px !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-prev {
    top: 110% !important;
    left: calc(100% - 70px) !important;
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-slider {
    top: -60px;
    @include max($s) {
      top: 0;
    }
  }

  .slick-track {
    @include max($s) {
      padding-bottom: 10px;
    }
  }

  .slick-slide {
    // @include max($s) {
    //   padding-right: 40px;
    // }
  }
  .slick-arrow {
    @include max($s) {
      display: none !important;
    }
  }
}
