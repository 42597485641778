@import "./service-bonuse/service-bonuse.scss";
@import "./service-warranty/service-warranty.scss";
@import "./service-pay/service-pay.scss";
@import "./service-shop/service-shop.scss";

.service-page {
  color: $n_darkest;
  line-height: 20.7px;

  margin-bottom: 250px;
  @include max($s) {
    // padding-bottom: 70px;
    margin-bottom: 100px;
  }
  &__row {
    @include max($s) {
      flex-direction: column-reverse;
    }
  }
  &__nav {
    &-list {
      @include max($s) {
        display: flex;
        margin-bottom: 50px;
      }
    }
    &-item:not(:last-child) {
      margin-bottom: 20px;
      @include max($s) {
        margin-bottom: 0;
      }
    }
    &-item {
      transition: all 0.3s linear;



      a.active {
        color: $b_indigo;
      }

      a:hover{
        text-decoration: none;
        span{
          text-decoration: underline;
        }
        i{
          text-decoration: none;
        }
      }

      & i {
        margin-right: 10px;
        font-size: 24px;

        @include max($s) {
          margin-right: 35px;
        }
      }
      & span {
        @include max($s) {
          display: none;
        }
      }
    }
  }
  &__title {
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
    font-family: "Travels", sans-serif;
    margin-bottom: 40px;
    @include max($s) {
      font-family: "Commons";
      font-size: 24px;
      line-height: 26px;
      font-weight: 500;
    }
  }
  // &__sub-title {

  //   @include max($s) {
  //     font-family: "Commons";
  //     font-size: 24px;
  //     line-height: 26px;
  //     font-weight: 500;
  //   }
  // }
  &__info {
    line-height: 20.7px;
    color: $n_dark;
    margin-bottom: 40px;
  }
  &__list {
    &:last-child {
      padding-bottom: 60px;
    }
    &:not(:last-child) {
      padding-bottom: 60px;
      // border-bottom: 1px solid $b_light;
      // margin-bottom: 60px;
    }

    &.no-line {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .service-page__item {
      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid $b_light;
        margin-bottom: 20px;
      }
      &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        & i {
          transition: all 0.3s linear;
          &.up {
            transform: rotate(180deg);
          }
        }
        &.open {
          & ~ div {
            margin-top: 20px;
            opacity: 1;
            height: 100%;
            visibility: visible;
            overflow: auto;
          }
          & i {
            transform: rotate(180deg);
            @include max($s) {
              // transform: rotate(-90deg);
            }
          }
        }
      }
      &-title {
        font-family: "Travels", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0px;
        @include max($s) {
          font-family: "Commons";
          font-size: 20px;
          line-height: 26px;
        }
      }
      &-info {
        opacity: 0;
        visibility: hidden;
        height: 0;
        transition: all 0.3s linear;
        overflow: hidden;
      }
    }
  }
  .service-page__item {
    ul {
      list-style: disc none;
      margin-left: 15px;
      li {
        font-family: "Commons";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;

        margin-bottom: 20px;

        @include max($s) {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
}
