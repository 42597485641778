.hero {
  padding-top: 10px;
  padding-bottom: 106px;
  background-color: $n_darkest;
  @include max($s) {
    padding-top: 20px;
    padding-bottom: 1px;
    margin-bottom: -1px;
  }

  .slick-current {
    .hero__slide {
      pointer-events: auto;
    }
  }

  &__slide {
    pointer-events: none;
    display: flex;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
  }
  .slick-list {
    padding-top: 60px !important;

    @include max($s) {
      padding-top: 0px !important;
    }
  }
  .slick-slide {
    cursor: pointer;
  }

  .arrow-wrp {
    margin-top: 10px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .slick-dots {
    li {
      div {
        transition: all 0.3s linear;
      }
      &.slick-active > div {
        //увеличение активной точечки пагинации ! проблема - отступы между ними
        width: 16px !important;
        background-color: $b_light !important;
      }
    }
  }

  .slick-dots {
    @include max($s) {
      bottom: -40px !important;
    }
  }

  .slick-slider {
    .slick-track {
      @include max($s) {
        padding-top: 0px;
      }
    }
    .slick-slide {
      position: relative;
      padding: 0px 20px;
      box-sizing: border-box;
      // padding-right: 20px;
      top: 0px;
      transition: all linear 0.3s;
      margin-top: 50px;
      @include max($s) {
        margin-top: 0px !important;
      }

      &.slick-active {
        transform: translateY(-60px);
        @include max($s) {
          transform: translateY(0px);
        }
      }
      & > div {
        width: calc(100vw - 40px);
        height: 473px;
        max-width: 1240px;
        @include max($s) {
          width: calc(100vw - 40px);
          height: 357px;
        }

        & > div {
          width: calc(100vw - 40px);
          height: 100%;
          max-width: 1240px;
          @include max($s) {
            width: calc(100vw - 40px);
            height: 100%;
          }
        }
      }
      img {
        width: calc(100vw - 40px);
        max-width: 1240px;
        @include max($s) {
          width: calc(100vw - 40px);
          height: 100%;
        }
      }
    }
  }
  .slick-next,
  .slick-prev {
    position: relative;
  }
  .slick-next {
    right: 0;
    @include max($s) {
      display: none !important;
    }
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }

  .slick-prev {
    left: 0;
    margin-right: 10px;
    @include max($s) {
      display: none !important;
    }
    &::before {
      font-family: "icons" !important;
      content: "\e900" !important;
    }
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1 !important;
    color: $b_sky !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: $b_indigo !important;
  }
}
